<template>
  <div></div>
</template>

<script>
export default {
  created () {
    const user = this.$route.query.user
    const token = this.$route.query.token

    localStorage.setItem('user', user)
    localStorage.setItem('USER_TOKEN', token)

    this.$router.push({ name: 'frontend' })
  },

  name: 'FacebookLogin'
}
</script>
